import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from 'ag-grid-react';
import { useMemo } from 'react';
import { useRef } from 'react';

const FRPAgGrid = (props) => {
    const gridRef = useRef();

    const defaultColDef = useMemo(() => {
        return {
            flex : 1,
            width: 100,
            editable: false,
            suppressMovable: true,
            floatingFilter:false,
            tooltipComponentParams: { color: '#ececec' }
        };
    }, []);

    return (
        <AgGridReact
            ref={props.gridRef}
            columnDefs={props.columnDefs}
            rowData={props.rowData}
            pagination={false}
            defaultColDef={defaultColDef}
            frameworkComponents={props.frameworkComponents}
            tooltipShowDelay={0}
        />
    );
}

export default FRPAgGrid;