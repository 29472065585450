import React, {useState} from 'react';

const InputBoxCellRenderer = (props) => {

  const [selectedValue, setSelectedValue] = useState(props.value);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    props.node.setDataValue(props.colDef.field, event.target.value);
  };

  return (
   <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop :'5px' }}>
      <input placeholder= {props.placeholder} maxLength={props.maxLength} alt="cell" style={{ width: '100%', height: '30px' }} value={selectedValue} onChange={handleChange}></input>
    </div>
  );
};

export default InputBoxCellRenderer;