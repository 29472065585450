import React from 'react';

const ImageCellRenderer = (props) => {
  //const base64Image = arrayBufferToBase64(props.value);
  const base64Image = props.value;
  const imageUrl = `data:application/pdf;base64,${base64Image}`;

  return (
   <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop :'5px' }}>
    <>
    { (props.value === "" || props.value === null) ? 
      null
    : <iframe src={imageUrl} type="application/jpeg" width="100%" height="670px" /> }
      </>
    </div>
  );
};

// Function to convert array buffer to base64
function arrayBufferToBase64(buffer) {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  for (let i = 0; i < bytes.byteLength; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

export default ImageCellRenderer;