import React from "react";
import { Routes, Route } from 'react-router-dom';

import Login from "./components/user/login";
import Protected from "./shared/protected-routing";
import WBOutputReview from "./components/wedge-builder/wb-output-review";
import WBHome from "./components/wedge-builder/wb-home";
import PermanentIssueRegister from "./components/wedge-builder/permanent-issue-register";

export const routes = [

    <Routes>
        <Route exact path="/" Component={Login} ></Route>

        <Route exact path="/wb-home" element={
            <Protected component={"wb-home"}>
                <WBHome />
            </Protected>
        }></Route>

        <Route exact path="/wb-output-review" element={
            <Protected component={"wb-output-review"}>
                <WBOutputReview />
            </Protected>
        }></Route>

        <Route exact path="/permanent-issue-register" element={
            <Protected component={"permanent-issue-register"}>
                <PermanentIssueRegister />
            </Protected>
        }></Route>
    </Routes>
];


