import React, { useContext } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useNavigate } from 'react-router-dom';
import { routes } from "./app.routing";
import { toast } from 'react-toastify';
import { GlobalStateContext } from './shared/GlobalStateContext';

const IdleLogout = () => {
    const navigate = useNavigate();
    const { isAuthenticated, setIsAuthenticated } = useContext(GlobalStateContext);

    const idleTimeout = 300000; // 5 minutes

    const handleOnIdle = () => {
        toast.success('Session has expired !! Please Re-Login');
        setIsAuthenticated(false);
        navigate('/');
    };

    const idleTimer = useIdleTimer({
        timeout: idleTimeout,
        onIdle: handleOnIdle,
        debounce: 500
    });

    return (
        <>
            {routes}
        </>
    );
};

export default IdleLogout;
