
import Header from "../../shared/layouts/header";
import { useLocation } from "react-router-dom/dist";
import { useState, useEffect } from "react";
import { getOutputReview } from "../../shared/services/wb-output-review-api-services";
import FRPAgGrid from "../../shared/ag-grid/frp-ag-grid";
import { Backdrop } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import ImageCellRenderer from "../../shared/ag-grid/cellrederer/imageCellRenderer";
import DropdownCellRenderer from "../../shared/ag-grid/cellrederer/dropdownCellRenderer";
import InputBoxCellRenderer from "../../shared/ag-grid/cellrederer/inputBoxCellRenderer";
import Button from "@mui/material/Button";
import { useRef } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from "@mui/material/DialogTitle";
import { postOutputReview } from "../../shared/services/wb-output-review-api-services";

const WBOutputReview = () => {

    const gridRef = useRef(null);
    const [selectedCaseID, setSelectedCaseID] = useState(null);
    const [selectedRequestID, setSelectedRequestID] = useState(null);
    const [openLoading, setOpenLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenForValidation, setIsOpenForValidation] = useState(false);
    const [validationErrMsg, setValidationErrMsg] = useState(null);
    const [rowData, setRowData] = useState([]);
    const [originalRowData, setOriginalRowData] = useState([]);
    let isValidFormNumber = "";
    let editedRowData = [];

    const location = useLocation();
    const navigate = useNavigate();

    const options = [
        { label: 'Select', Id: 0 },
        { label: 'Issue', Id: 1 },
        { label: 'Non-Issue', Id: 2 }
    ]

    const cResultOptions = [
        { label: 'Issue', Id: 0 },
        { label: 'Non-Issue', Id: 1 }
    ]

    let colDefs = [
        {
            headerName: "SN",
            field: 'sn',
            /* valueGetter: "node.rowIndex + 1", */
            filter: "agNumberColumnFilter",
            width: 30,
            minWidth: 30,
            maxWidth: 70,
        },
        {
            headerName: 'Document Name',
            field: 'documentName',
            filter: "agTextColumnFilter",
            width: 30,
            minWidth: 100,
            maxWidth: 150,
            autoHeight: true,
            wrapHeaderText: true,
            flex: 1,
            tooltipField: 'documentName'
        },
        {
            headerName: 'Line of Business',
            field: 'lineOfBusiness',
            filter: "agTextColumnFilter",
            width: 30,
            minWidth: 100,
            maxWidth: 130,
            autoHeight: true,
            wrapHeaderText: true,
            flex: 1
        },
        {
            headerName: 'Issue Name',
            field: 'issueName',
            filter: "agTextColumnFilter",
            width: 30,
            minWidth: 100,
            maxWidth: 160,
            autoHeight: true,
            wrapHeaderText: true,
            flex: 1,
            tooltipField: 'issueName'
        },
        {
            headerName: 'Result',
            field: 'result',
            filter: "agTextColumnFilter",
            width: 30,
            minWidth: 100,
            maxWidth: 130,
            autoHeight: true,
            wrapHeaderText: true,
            flex: 1
        },
        {
            headerName: 'Confirm Result',
            field: 'confirmResult',
            filter: "agTextColumnFilter",
            width: 30,
            minWidth: 100,
            maxWidth: 150,
            autoHeight: true,
            wrapHeaderText: true,
            flex: 1,
            cellRenderer: DropdownCellRenderer,
            cellRendererParams: {
                options: cResultOptions,
            },
        },
        {
            headerName: 'Issue Type',
            field: 'issueType',
            filter: "agTextColumnFilter",
            width: 30,
            minWidth: 100,
            maxWidth: 130,
            autoHeight: true,
            wrapHeaderText: true,
            flex: 1
        },
        {
            headerName: 'Page Number',
            field: 'pageNumber',
            filter: "agTextColumnFilter",
            width: 30,
            minWidth: 80,
            maxWidth: 105,
            autoHeight: true,
            wrapHeaderText: true,
            flex: 1
        },
        {
            headerName: 'Form Number',
            field: 'formNumber',
            filter: "agTextColumnFilter",
            width: 30,
            minWidth: 100,
            maxWidth: 165,
            autoHeight: true,
            wrapHeaderText: true,
            cellRenderer: InputBoxCellRenderer,
            cellRendererParams: {
                placeholder: 'Enter Form Number',
                maxLength: 50
            },
            flex: 1
        },
        {
            headerName: 'Permanent Status',
            field: 'permanentStatus',
            filter: "agTextColumnFilter",
            width: 30,
            minWidth: 100,
            maxWidth: 150,
            autoHeight: true,
            wrapHeaderText: true,
            cellRenderer: DropdownCellRenderer,
            cellRendererParams: {
                options: options
            },
            flex: 1,
        },
        {
            headerName: 'Policy Page Screenshot',
            field: 'policyPageScreenshot',
            width: 30,
            minWidth: 100,
            maxWidth: 800,
            autoHeight: true,
            wrapHeaderText: true,
            flex: 1,
            cellRenderer: ImageCellRenderer,
        }
    ]

    useEffect(() => {

        if (location.state != null) {
            setSelectedCaseID(location.state.caseID);            
            setSelectedRequestID(location.state.requestID);

        }

        async function fetchOutputReview() {
            setOpenLoading(true);
            const data = await getOutputReview(location.state.caseID);
            if (data.data !== null) {
                setOriginalRowData(JSON.parse(JSON.stringify(data.data)));
                setRowData(data.data);
                setOpenLoading(false);
            } else {
                setOpenLoading(false);
                toast.error(data.error.message);
            }
        }

        fetchOutputReview();

    }, []);

    const postOutputReviews = async (action) => {
        const body = [];
        if (editedRowData.length > 0) {
            editedRowData.forEach(row => {
                body.push({
                    "id": row.id,
                    "form_number": row.formNumber,
                    "permanent_status": row.permanentStatus,
                    "confirm_results": row.confirmResult
                })
            });
        }
        const data = await postOutputReview(selectedCaseID, JSON.stringify(body), action);
        return data;
    }

    const onSaveAsDraft = async () => {
        let msgErr = validation();
        if (msgErr === "") {
            if (isValidFormNumber === "") {
                setOpenLoading(true);
                if (editedRowData.length > 0) {
                    const data = await postOutputReviews('save');
                    if (data.data != 'Results saved successfully.') {
                        setOpenLoading(false);
                        toast.error(data.error);
                        return;
                    }
                }
                setOpenLoading(false);
                toast.success('The Case ID: ' + selectedCaseID + ' has been successfully saved');
                navigate('/wb-home');
            }
            else {
                setIsOpenForValidation(true);
                setValidationErrMsg(isValidFormNumber);
            }
        }
        else {
            setIsOpenForValidation(true);
            setValidationErrMsg(msgErr);
        }
    }

    const onConfirm = async () => {
        let msgErr = validation();
        if (msgErr === "") {
            if (isValidFormNumber === "") {
                setOpenLoading(true);
                const data = await postOutputReviews('confirm');
                if (data.data != 'Results confirmed successfully.' && data.data != 'Results saved and confirmed successfully.' && data.data != 'Results confirmed successfully but update Status to the OneFRP failed.') {
                    setOpenLoading(false);
                    toast.error(data.error);
                    return;
                }
                setOpenLoading(false);
                toast.success('The result reports for the Case ID: ' + selectedCaseID + ' has been successfully generate');
                navigate('/wb-home');
            }
            else {
                setIsOpenForValidation(true);
                setValidationErrMsg(isValidFormNumber);
            }
        }
        else {
            setIsOpenForValidation(true);
            setValidationErrMsg(msgErr);
        }
    }

    const onClose = () => {

        const alterFormNumberData = compareAndFilter(rowData, originalRowData, 'formNumber', null, null);
        let alterFormNumber = [...alterFormNumberData];

        const alterPermanentStatusData = compareAndFilter(rowData, originalRowData, 'permanentStatus', null, null);
        let alterPermanentStatus = [...alterPermanentStatusData];

        const alterConfirmResultData = compareAndFilter(rowData, originalRowData, 'confirmResult', null, null);
        let alterConfirmResult = [...alterConfirmResultData];

        if (alterFormNumber.length > 0 || alterPermanentStatus.length > 0 || alterConfirmResult.length > 0 ) setIsOpen(true);
        else navigate('/wb-home');
    }

    const handleCancel = () => {
        setIsOpen(false);
    };

    const handleDiscradChanges = () => {
        setIsOpen(false);
        toast.success('The changes made to Case ID: ' + selectedCaseID + ' has been discarded');
        navigate('/wb-home');
    }

    const handleOK = () => {
        setValidationErrMsg(null);
        setIsOpenForValidation(false);
    }

    const isValidString = (data) => {
        let invalidData = "";
        if (data != null && data != undefined) {
            let inputValue = data.split(',');

            inputValue.forEach(element => {
                const regex = /^[a-zA-Z0-9,]*$/;
                if (!(regex.test(element.trim()))) {
                    invalidData += element + ", ";
                }
            });
        }

        return invalidData;
    }

    const validation = () => {
        let errMsg = "SN - ";
        let isValidFormNo = "Invalid form number entered in - \nSN - ";
        let isError = false;

        gridRef.current.api.forEachNode((rowNode) => {
            if ((rowNode.data.formNumber !== "" && rowNode.data.formNumber != null) && (rowNode.data.permanentStatus === "Select" || rowNode.data.permanentStatus == null)) {
                errMsg += '' + (isError == true ? ',' : '') + ' (' + (rowNode.rowIndex + 1) + ")";
                isError = true;
            }

            if ((rowNode.data.formNumber === "" || rowNode.data.formNumber == null) && (rowNode.data.permanentStatus != "Select" && rowNode.data.permanentStatus != null)) {
                errMsg += '' + (isError == true ? ',' : '') + ' (' + (rowNode.rowIndex + 1) + ")";
                isError = true;
            }

            if (rowNode.data.formNumber !== "") {
                let invalidText = "";
                invalidText = isValidString(rowNode.data.formNumber);
                if (invalidText != "") {
                    isValidFormNo += "(" + (rowNode.rowIndex + 1) + ") , "; 
                    isValidFormNumber = isValidFormNo;
                }
            }
        });

        if(isValidFormNumber != "") isValidFormNumber += "\nForm number with special characters and spaces is not allowed."; 
        if (!(isError)) {
            errMsg = "";
            const filteredData = compareAndFilter(rowData, originalRowData, 'formNumber', 'permanentStatus','confirmResult');
            editedRowData = [...filteredData];
        }
        else {
            errMsg += "\nBoth Form Number and Permanent Status columns has to be \nupdated or kept as it is."
        }

        return errMsg;
    }

    const compareAndFilter = (arr1, arr2, property1, property2, property3) => {
        return arr1.filter(item1 => {
            const item2 = arr2.find(item2 => item2.id === item1.id);
            if (property1 != null && property2 != null && property3 !=null) {
                return item2 && (item2[property1] !== item1[property1] || (item2[property2] !== item1[property2] || (item1[property2] === 'Issue' || item1[property2] === 'Non-Issue')) || item2[property3] !== item1[property3])
            }
            else if (property1 != null && property2 === null && property3 === null) {
                return item2 && (item2[property1] !== item1[property1])
            }
        });
    };

    return (
        <>
            <div id="content-wrapper" class="d-flex flex-column" style={{ flex: '1' }}  >
                <div id="content">
                    <Header caseID={'Request ID: ' + selectedRequestID} />
                    <div class="container-fluid">

                        <hr class="sidebar-divider my-0" />
                        <div class="row">
                            <div class="col-xl-12 col-md-6 mb-4">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xl-12 col-md-12 mb-4">
                                <div class="card shadow mb-4">
                                    <div
                                        class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                    </div>
                                    <div class="card-body">
                                        <div className={"ag-theme-quartz"} style={{ width: '100%', height: 655 }}>
                                            <FRPAgGrid
                                                gridRef={gridRef}
                                                columnDefs={colDefs}
                                                rowData={rowData}
                                            ></FRPAgGrid>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="col-xl-12 col-md-12 mb-12">
                                            <div className="review-button">
                                                <Button className={rowData.length === 0 ? "btnprimary-disable" : "btnprimary"} onClick={onSaveAsDraft} disabled={rowData.length === 0 ? true : false} >
                                                    Save
                                                </Button>
                                                <Button className={rowData.length === 0 ? "btnprimary-disable" : "btnprimary"} onClick={onConfirm} disabled={rowData.length === 0 ? true : false}>
                                                    Confirm
                                                </Button>
                                                <Button className="btnprimary" onClick={onClose} >
                                                    Close
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={openLoading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>

                <Dialog
                    open={isOpen}
                    onClose={handleCancel}
                    aria-describedby="alert-dialog-slide-description"
                >
                    
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            Are you sure you want to discard all changes made?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDiscradChanges}>Discard Changes</Button>
                        <Button onClick={handleCancel}>Cancel</Button>
                    </DialogActions>
                </Dialog>


                <Dialog
                    open={isOpenForValidation}
                    onClose={handleOK}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle>{"Error occurred for results in -"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            <pre>{validationErrMsg}</pre>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleOK}>OK</Button>
                    </DialogActions>
                </Dialog>
            </div>
        </>
    );
}

export default WBOutputReview;