import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import moment from 'moment';

const CustomDateFilter = forwardRef((props, ref) => {
  const [filterValue, setFilterValue] = useState('');
  const inputRef = useRef();

  useEffect(() => {
    if (props.filterChangedCallback) {
      props.filterChangedCallback();
    }
  }, [filterValue]);

  useImperativeHandle(ref, () => ({
    isFilterActive() {
      return filterValue !== '';
    },
    doesFilterPass(params) {
      const cellValue = moment(params.data[props.column.colId]).format('MM-DD-yyyy');
      if (!cellValue) return false;
      const cellDate = moment(cellValue, 'MM-DD-yyyy').toDate();
      const filterDate = moment(filterValue, 'MM-DD-yyyy').toDate();
      return cellDate >= filterDate;
    },
    getModel() {
      return { value: filterValue };
    },
    setModel(model) {
      setFilterValue(model ? model.value : '');
    }
  }));

  const onDateChange = (event) => {
    setFilterValue(event.target.value);
  };

  return (
    <div>
      <input
        type="text"
        ref={inputRef}
        placeholder="MM-DD-YYYY"
        value={filterValue}
        onChange={onDateChange}
      />
    </div>
  );
});

export default CustomDateFilter;
