import Header from "../../shared/layouts/header";
import { useLocation } from "react-router-dom/dist";
import { useState, useEffect, useContext } from "react";

import { getPermanentIssueRegister, postPermanentIssueRegister } from "../../shared/services/permanent-issue-register-api-services";
import FRPAgGrid from "../../shared/ag-grid/frp-ag-grid";
import { Backdrop } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
// import ImageCellRenderer from "../../shared/ag-grid/cellrederer/imageCellRenderer";
// import DropdownCellRenderer from "../../shared/ag-grid/cellrederer/dropdownCellRenderer";
import InputBoxCellRenderer from "../../shared/ag-grid/cellrederer/inputBoxCellRenderer";
import Button from "@mui/material/Button";
import { useRef } from "react";
// import { useCallback } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { GlobalStateContext } from '../../shared/GlobalStateContext';


const PermanentIssueRegister = () => {

    const gridRef = useRef(null);
    const [openLoading, setOpenLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [rowData, setRowData] = useState([]);
    const [isOpenForValidation, setIsOpenForValidation] = useState(false);
    const [validationErrMsg, setValidationErrMsg] = useState(null);
    const [originalRowData, setOriginalRowData] = useState([]);
    const {selectedModule, setSelectedModule} = useContext(GlobalStateContext);
    let editedRowData = [];

    const location = useLocation();
    const navigate = useNavigate();

    async function fetchPermanentIssueRegister(selectedModule) {
        setOpenLoading(true);
        const ruleType = selectedModule === "Wedge Builder" ? "WEDGE" : "GAP";
        const data = await getPermanentIssueRegister(ruleType);
        if (data.data !== null) {
            setOriginalRowData(JSON.parse(JSON.stringify(data.data)));
            setRowData(data.data);
            setOpenLoading(false);
        } else {
            setOpenLoading(false);
        }
    }

    useEffect(() => {
        fetchPermanentIssueRegister(selectedModule);
    }, [selectedModule]);

    let colDefs = [
        {
            headerName: 'Line of Business',
            field: 'line_of_business',
            filter: "agTextColumnFilter",
            width: 30,
            minWidth: 200,
            maxWidth: 250,
            autoHeight: true,
            wrapHeaderText: true,
            flex: 1
        },
        {
            headerName: 'Issue Name',
            field: 'rule_name',
            filter: "agTextColumnFilter",
            width: 30,
            minWidth: 200,
            maxWidth: 250,
            autoHeight: true,
            wrapHeaderText: true,
            tooltipField: 'rule_name',
            flex: 1
        },
        {
            headerName: 'Permanent Issue',
            field: 'permanent_issue_list',
            filter: "agTextColumnFilter",
            width: 30,
            minWidth: 680,
            maxWidth: 680,
            autoHeight: true,
            wrapHeaderText: true,
            cellRenderer: InputBoxCellRenderer,
            cellRendererParams: {
                placeholder: ''
            },
            flex: 1
        },
        {
            headerName: 'Permanent Non-Issue',
            field: 'permanent_non_issue_list',
            filter: "agTextColumnFilter",
            width: 30,
            minWidth: 680,
            maxWidth: 680,
            autoHeight: true,
            wrapHeaderText: true,
            cellRenderer: InputBoxCellRenderer,
            cellRendererParams: {
                placeholder: ''
            },
            flex: 1
        }
    ]

    const delay = (ms) => new Promise((resolve) => {
        setTimeout(resolve, ms)
    });

    const postPIR = async () => {
        const body = [];
        if (editedRowData.length > 0) {
            editedRowData.forEach(row => {
                body.push({
                    "rule_id": row.rule_id,
                    "permanent_issue_list": row.permanent_issue_list,
                    "permanent_non_issue_list": row.permanent_non_issue_list
                })
            });
        }
        const data = await postPermanentIssueRegister(JSON.stringify(body));
        return data;
    }

    const onSave = async () => {
        let msgErr = validation();
        if (msgErr === "") {
            if (editedRowData.length > 0) {
                setOpenLoading(true);
                if (editedRowData.length > 0) {
                    const data = await postPIR();
                    if (data.data != 'Rules updated successfully in schema frp with array data.' && data.data != 'Rules updated successfully in schema frp with array data.') {
                        setOpenLoading(false);
                        toast.error(data.error);
                        return;
                    }
                }
                fetchPermanentIssueRegister();
                setOpenLoading(false);
                toast.success('The Permanent Issue Register has been successfully updated.');
            }
        }
        else {
            setIsOpenForValidation(true);
            setValidationErrMsg(msgErr);
        }
    }

    const validation = () => {
        let errMsg = "Invalid form number\n";
        let isError = false;
        gridRef.current.api.forEachNode((rowNode) => {
            
            let invalidText = "";
            invalidText = isValidString(rowNode.data.permanent_issue_list);
            if (invalidText != "") {
                errMsg += invalidText.trim();
                isError = true;
            }

            invalidText = isValidString(rowNode.data.permanent_non_issue_list);
            if (invalidText != "") {
                errMsg += invalidText.trim();
                isError = true;
            }
        });

        if (!(isError)) {
            errMsg = "";
            const filteredData = compareAndFilter(rowData, originalRowData, 'permanent_issue_list', 'permanent_non_issue_list');
            editedRowData = [...filteredData];
        }
        else errMsg += "  \nForm number with special characters and \nspaces are not allowed.";

        return errMsg;
    }

    const isValidString = (data) => {
        let invalidData = "";
        if (data != null && data != undefined) {
            let inputValue = data.split(',');

            inputValue.forEach(element => {
                const regex = /^[a-zA-Z0-9,]*$/;
                if (!(regex.test(element.trim()))) {
                    invalidData += element + ",";
                }
            });
        }

        return invalidData;
    }

    const compareAndFilter = (arr1, arr2, property1, property2) => {
        return arr1.filter(item1 => {
            const item2 = arr2.find(item2 => item2.id === item1.id);
            if (property1 != null && property2 != null) {
                return item2 && (item2[property1] !== item1[property1] || item2[property2] !== item1[property2])
            }
            else if (property1 != null && property2 === null) {
                return item2 && (item2[property1] !== item1[property1])
            }
        });
    };

    const handleOK = () => {
        setValidationErrMsg(null);
        setIsOpenForValidation(false);
    }

    const onClose = () => {
        const alterWhitelistData = compareAndFilter(rowData, originalRowData, 'permanent_issue_list', null);
        let alterWhitelist = [...alterWhitelistData];

        const alterBlacklistData = compareAndFilter(rowData, originalRowData, 'permanent_non_issue_list', null);
        let alterBlacklist = [...alterBlacklistData];

        if (alterWhitelist.length > 0 || alterBlacklist.length > 0) setIsOpen(true);
        else navigate('/wb-home');
    }

    const handleDiscradChanges = () => {
        setIsOpen(false);
        toast.success('The changes made to the Permanent Issue Register has been discarded.');
        navigate('/wb-home');
    }

    const handleCancel = () => {
        setIsOpen(false);
    };

    return (
        <>
            {/* <Sidebar /> */}
            <div id="content-wrapper" class="d-flex flex-column" style={{ flex: '1' }}  >
                <div id="content">
                    <Header />
                    <div class="container-fluid">
                        <hr class="sidebar-divider my-0" />
                        <div class="row">
                            <div class="col-xl-12 col-md-6 mb-4">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xl-12 col-md-12 mb-4">
                                <div class="card shadow mb-4">
                                    <div
                                        class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                        <h6 class="infoMessage">Revise or add new form number to the Permanent Issue Register table
                                        </h6>
                                    </div>
                                    <div class="card-body">
                                        <div className={"ag-theme-quartz"} style={{ width: '100%', height: 655 }}>
                                            <FRPAgGrid
                                                gridRef={gridRef}
                                                columnDefs={colDefs}
                                                rowData={rowData}
                                            ></FRPAgGrid>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="col-xl-12 col-md-12 mb-12">
                                            <div className="review-button">
                                                <Button className={rowData.length === 0 ? "btnprimary-disable" : "btnprimary"} onClick={onSave} disabled={rowData.length === 0 ? true : false}>
                                                    Save
                                                </Button>
                                                <Button className="btnprimary" onClick={onClose} >
                                                    Close
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={openLoading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>

                <Dialog
                    open={isOpen}
                    onClose={handleCancel}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            Are you sure you want to discard all changes made?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDiscradChanges}>Discard Changes</Button>
                        <Button onClick={handleCancel}>Cancel</Button>
                    </DialogActions>
                </Dialog>


                <Dialog
                    open={isOpenForValidation}
                    onClose={handleOK}
                    aria-describedby="alert-dialog-slide-description"
                >
                    {/* <DialogTitle>{"Error occurred for results in -"}</DialogTitle> */}
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            <pre>{validationErrMsg}</pre>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleOK}>OK</Button>
                    </DialogActions>
                </Dialog>
            </div>
        </>
    );
}
export default PermanentIssueRegister;
