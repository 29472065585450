import { getAPI, postAPI } from "../services/api-services";

const baseURL = process.env.REACT_APP_API

export const getPermanentIssueRegister = async (ruleType = null) => {
    const queryParams = ruleType ? `?rule_type=${ruleType}` : '';
    const url = `${baseURL}/api/getRulesAdmin${queryParams}`;
    const { data, loading, error } = await getAPI(url);
    return { data, error, loading };
}

export const postPermanentIssueRegister = async (body) => {
    const url = baseURL + '/api/updateRulesAdmin';
    const { data, loading, error } = await postAPI(url,body);
    return { data, error, loading };
  }
