import axios from "axios";

export const getAPI = async (_url) => {
    try {

        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: _url,
            headers: {
                'Content-Type': 'application/json'
            }
        };

        const response = await axios.request(config);
        return { data: response.data, loading: false, error: null };
    } catch (error) {
        return { data: null, loading: false, error: error };
    }
};

export const postAPI = async (_url, _body) => {
    try {
        let config = {
            method: 'post',
            url: _url,
            headers: {
                'Content-Type': 'application/json'
            },
            data: _body
        };
        const response = await axios.request(config);
        return { data: response.data, loading: false, error: null };
    } catch (error) {
        return { data: null, loading: false, error: error };
    }
};

export const putAPI = async (_url, _body, _header) => {
    try {
        let config = {
            method: 'put',
            url: _url,
            headers: _header,
            data: _body
        };
        const response = await axios.request(config);
        return { data: response.data, loading: false, error: null };
    } catch (error) {
        return { data: null, loading: false, error: error };
    }
};

export const deleteAPI = async (url, id) => {
    try {
        const response = await axios.delete(url, id);
        return { data: response.data, loading: false, error: null };
    } catch (error) {
        return { data: null, loading: false, error: error };
    }
};
